@import "mixins";

@function px-to-rem($px) {
  $rem-list: ();
  @for $i from 1 through 100 {
    $rem-list: append($rem-list, $i / $px#{rem});
  }
  @return $rem-list;
}
// end function

$font-size-root: 16; // !default allow override value
$pxs: px-to-rem($font-size-root);
$scale-1-1: 1.1;
$time-300ms: .3s;
$time-600ms: .6s;

// end variable
html {
  font-size: 16px;
}

body {
  font-family: "Open Sans", serif;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    &:hover {
      text-decoration: none;
      color: unset;
    }
  }

  p {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0;
  }

  .btn {
    &:hover {
      color: unset;
    }
  }

  .form-control {
    &:focus {
      outline: 0;
      box-shadow: unset;
    }
  }

  h1, h3{
    margin-bottom: 0;
  }

  b{
    font-weight: 600;
  }

}