@mixin wh($value) {
  width: $value;
  height: $value;
}

@mixin d-flex($inline: false) {
  @if $inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  align-items: center;
  justify-content: center;
}

@mixin transition-all($time) {
  transition: all $time;
  -moz-transition: all $time;
  -o-transition: all $time;
  -webkit-transition: all $time;
}

@mixin transform-scale($value) {
  transform: scale($value);
  -o-transform: scale($value);
  -moz-transform: scale($value);
  -webkit-transform: scale($value);
  -ms-transform: scale($value);
}

@mixin border-radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin transform-rotate($deg) {
  transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg);
}

// Extra large devices
@mixin xl-devices {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

// Large devices
@mixin lg-devices {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

// Medium devices
@mixin md-devices {
  @media screen and (max-width: 768px) {
    @content;
  }
}

// Small devices
@mixin sm-devices {
  @media screen and (max-width: 576px) {
    @content;
  }
}