@import "utilities";
@import "mixins";

@include md-devices {
  html {
    font-size: 14px;
  }
}

.t-1585557880 {
  padding: 8px 0;

  .t-1585557880-left {
    a {
      width: 121px;
      display: block;

      img {
        width: 100%;
      }
    }
  }

  .t-1585557880-right {
    float: right;
    height: 100%;

    ul {
      height: 100%;
      display: flex;

      li {
        > a {
          font-size: nth($pxs, 14);
          text-transform: uppercase;
          color: rgb(66, 66, 66);
          padding: 0 20px;
          height: 100%;
          display: flex;
          align-items: center;

          &.fa-facebook-square {
            font-size: nth($pxs, 24);
            color: orange;
          }
        }

        > div {
          display: flex;
          align-items: center;
          height: 100%;

          > a {
            font-size: nth($pxs, 14);
            text-transform: uppercase;
            padding: 0 4px;
            color: rgb(68, 68, 68);
          }
        }

        @include sm-devices {
          @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
              display: none;
            }
          }
        }

      }
    }
  }
}

// .t-1585557880

.t-1585559184{
  background: rgb(255, 172, 48);
  padding: 30px 0;
  box-shadow: 0 1px 2px 0 rgba(130, 130, 130, 0.55) inset;

  .t-1585559184-content{
    height: 420px;
    box-shadow: rgba(0, 0, 0, 0.49) 0 1px 3px 0;
  }

}
// .t-1585559184

.t-1585559364{

  h3{
    font-size: nth($pxs, 30);
    font-weight: 600;
    margin-bottom: 30px;
  }

  .t-1585559364-left{
    .text-item{
      display: flex;
      justify-content: space-around;
    }
  }
  .t-1585559364-right{

  }
}
// .t-1585559364

.t-1585557946 {
  background: rgba(255, 172, 48, 0.5);
  padding: 40px 0;
  color: #444444;

  @include sm-devices {
    padding: 20px 0;
  }

  .t-1585557946-left {
    p {
      font-size: nth($pxs, 14);
      font-weight: 600;

      &.title {
        font-size: nth($pxs, 20);
        margin-bottom: 20px;
      }
    }
  }

  .t-1585557946-center {
    @include sm-devices {
      margin-top: 10px;
    }

    p {
      font-size: nth($pxs, 13);
      font-weight: 600;

      > span {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  .t-1585557946-right {
    @include sm-devices {
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }

    p {
      font-size: nth($pxs, 16);
    }
  }
}